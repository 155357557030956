import React, { Component } from 'react'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { faSave } from '@fortawesome/pro-duotone-svg-icons'

class VerzoekToewijzingForm extends Component {
    constructor(props) {
        super(props)

        let ingangsDatum = null
        let toewijzingsDatum = null
        if (this.props.item && this.props.item.ingangs_datum) {
            ingangsDatum = this.props.kedo
                .utils()
                .dateFormat(this.props.item.ingangs_datum, 'YYYY-MM-DD')
        }
        if (this.props.item && this.props.item.toewijzings_datum) {
            toewijzingsDatum = this.props.kedo
                .utils()
                .dateFormat(this.props.item.toewijzings_datum, 'YYYY-MM-DD')
        }

        this.state = {
            saving: false,
            errors: false,
            error: false,
            errorResponse: null,
            product_categorie: this.props.item
                ? this.props.item.product_categorie
                : null,
            begindatum: ingangsDatum,
            product_code: this.props.item ? this.props.item.product_code : null,
            statusaanlevering: this.props.item
                ? this.props.item.statusaanlevering
                : null,
            toewijzing_nummer: this.props.item
                ? this.props.item.toewijzing_nummer
                : null,
            toewijzings_datum: toewijzingsDatum,
        }

        this.submit = this.submit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    addClient(dossierId) {
        // /module/ijwiwmo/client/add
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.post('/module/ijwiwmo/client/add/' + this.props.dossierId)
            .then((response) => {
                window.location.reload()
            })
            .catch((err) => {
                alert('Fout bij aanmaken cliënt')
            })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    submit() {
        const api = this.props.kedo.api()

        let data = {
            volume: this.state.volume,
            product_code: this.state.product_code,
            verwijzer_type: this.state.verwijzertype,
            product_categorie: this.state.product_categorie,
            verwijzernaam: this.state.verwijzer_naam,
            eenheid: this.state.eenheid,
            frequentie: this.state.frequentie,
            budget: this.state.budget,
            beschikkingnummer: this.state.beschikkingnummer,
            beschikkingingangsdatum: this.state.beschikkingingangsdatum,
            raamcontract: this.state.raamcontract,
            ingangsdatum: this.state.ingangsdatum,
            einddatum: this.state.einddatum,
            zorgverlenercode: this.state.zorgverlenercode,
        }

        this.setState({ saving: true })
        api.post('/module/ijwiwmo/vot/' + this.props.dossierId, data)
            .then((response) =>
                this.setState(
                    { saving: false, error: false, errorResponse: null },
                    () => this.props.onClose(true)
                )
            )
            .catch((errResponse) =>
                this.setState({
                    saving: false,
                    error: true,
                    errorResponse: errResponse,
                    errors: api.getErrorFromApiCall(errResponse.response),
                })
            )
    }

    renderErrorFeedback(errorMsg) {
        if (!errorMsg) {
            return
        }

        return (
            <Form.Control.Feedback type="invalid">
                {errorMsg}
            </Form.Control.Feedback>
        )
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal size={'lg'} show={true} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Toevoegen verzoek toewijzing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error === true &&
                    this.state.errorResponse.data &&
                    this.state.errorResponse.data.error &&
                    this.state.errorResponse.data.error.code === 404 ? (
                        <div className="alert alert-info field-grid-wrapper jwwmo-add-person">
                            <p>
                                De desbetreffende persoon is niet bekend voor
                                het berichtenverkeer, geef hier onder akkoord om
                                deze persoon door te sturen.
                            </p>
                            <btn
                                className="btn btn-primary"
                                onClick={() => this.addClient()}
                            >
                                {kedo.t('Create')}
                            </btn>
                            <a className="btn btn-secondary" href="">
                                {kedo.t('Cancel')}
                            </a>
                        </div>
                    ) : null}
                    <Form>
                        {this.state.errors !== false ? (
                            <Alert variant={'warning'}>
                                Er is iets mis gegaan bij het opslaan.
                            </Alert>
                        ) : null}
                        <Form.Group>
                            <Form.Label className={'required'}>
                                Ingangsdatum
                            </Form.Label>
                            <Form.Control
                                isInvalid={this.state.errors['ingangsdatum']}
                                onChange={this.onChange}
                                name={'ingangsdatum'}
                                value={this.state.ingangsdatum}
                                type={'date'}
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['ingangsdatum']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className={'required'}>
                                Einddatum
                            </Form.Label>
                            <Form.Control
                                isInvalid={this.state.errors['eindDatum']}
                                onChange={this.onChange}
                                name={'einddatum'}
                                value={this.state.einddatum}
                                type={'date'}
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['eindDatum']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className={'required'}>
                                Product categorie
                            </Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                name={'product_categorie'}
                                value={this.state.product_categorie}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Product code</Form.Label>
                            <Form.Control
                                isInvalid={this.state.errors['product_code']}
                                onChange={this.onChange}
                                name={'product_code'}
                                value={this.state.product_code}
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['product_code']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="eenheid">Eenheid</Form.Label>
                            <Form.Control
                                as={'select'}
                                isInvalid={this.state.errors['eenheid']}
                                onChange={this.onChange}
                                id="jwwmo-eenheid"
                                name="eenheid"
                            >
                                <option value=""></option>
                                <option value="01">Minuut</option>
                                <option value="04">Uur</option>
                                <option value="14">Etmaal</option>
                                <option value="16">Dagdeel (4 uur)</option>
                                <option disabled value="21">
                                    Week (vervallen)
                                </option>
                                <option disabled value="81">
                                    Benodigde tijdsduur of tijdsonafhankelijk
                                    (vervallen)
                                </option>
                                <option value="82">Stuks</option>
                                <option value="83">Euro&apos;s</option>
                                <option value="84">Stuks (inspanning)</option>
                            </Form.Control>
                            {this.renderErrorFeedback(
                                this.state.errors['eenheid']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Frequentie</Form.Label>
                            <Form.Control
                                as={'select'}
                                onChange={this.onChange}
                                isInvalid={this.state.errors['frequentie']}
                                id="jwwmo-frequentie"
                                name="frequentie"
                            >
                                <option value=""></option>
                                <option value="1">Per dag</option>
                                <option value="2">Per week</option>
                                <option disabled value="3">
                                    Per 4 weken (vervallen)
                                </option>
                                <option value="4">Per maand</option>
                                <option disabled value="5">
                                    Per jaar (vervallen)
                                </option>
                                <option value="6">
                                    Totaal binnen geldigheidsduur beschikking
                                </option>
                            </Form.Control>
                            {this.renderErrorFeedback(
                                this.state.errors['frequentie']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Volume</Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                isInvalid={this.state.errors['volume']}
                                id="jwwmo-volume"
                                name="volume"
                                value={this.state.volume}
                            />
                            <span className="help-block">
                                Als het een bedrag betreft, dan dit invullen IN
                                CENTEN (dus 1 euro = 100).
                            </span>
                            {this.renderErrorFeedback(
                                this.state.errors['volume']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="control-label">
                                Type Verwijzer
                            </Form.Label>
                            <Form.Control
                                isInvalid={this.state.errors['typeVerwijzer']}
                                required="required"
                                name="verwijzertype"
                                value={this.state.verwijzertype}
                                as={'select'}
                                onChange={this.onChange}
                                id="jwwmo-verwijzertype"
                            >
                                <option value="">
                                    {kedo.t('Choose an option')}
                                </option>
                                <option value="01">Gemeente</option>
                                <option value="02">Huisarts</option>
                                <option value="03">Jeugdarts</option>
                                <option value="04">
                                    Gecertificeerde instelling
                                </option>
                                <option value="05">Medisch specialist</option>
                                <option value="06">Zelfverwijzer</option>
                                <option value="07">Onbekend</option>
                                <option value="08">
                                    Rechter, Raad voor de Kinderbescherming of
                                    Officier van Justitie
                                </option>
                            </Form.Control>
                            {this.renderErrorFeedback(
                                this.state.errors['typeVerwijzer']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="control-label">
                                Verwijzer naam
                            </Form.Label>
                            <Form.Control
                                isInvalid={this.state.errors['verwijzerNaam']}
                                onChange={this.onChange}
                                required="required"
                                id="jwwmo-verwijzernaam"
                                name="verwijzer_naam"
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['verwijzerNaam']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="control-label">
                                ZorgverlenerCode
                            </Form.Label>
                            <Form.Control
                                isInvalid={
                                    this.state.errors['zorgverlenerCode']
                                }
                                required="required"
                                onChange={this.onChange}
                                id="jwwmo-zorgverlenercode"
                                name="zorgverlener_code"
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['zorgverlenerCode']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="control-label">
                                Raamcontract
                            </Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                as={'select'}
                                required="required"
                                name="raamcontract"
                                id="jwwmo-raamcontract"
                                className="form-control"
                            >
                                <option value="">
                                    {kedo.t('Choose an option')}
                                </option>
                                <option value="1">Ja</option>
                                <option value="2">Nee</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Beschikkingsnummer</Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                isInvalid={
                                    this.state.errors['Beschikkingsnummer']
                                }
                                name={'Beschikkingsnummer'}
                                value={this.state.Beschikkingsnummer}
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['Beschikkingsnummer']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="control-label">
                                Beschikking ingangsdatum
                            </Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                id="jwwmo-beschikkingingangsdatum"
                                as="date"
                                value=""
                                className="form-control"
                                name="beschikkingingangsdatum"
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['beschikkingingangsdatum']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="control-label">
                                Budget
                            </Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                id="jwwmo-budget"
                                name="budget"
                            />
                            {this.renderErrorFeedback(
                                this.state.errors['budget']
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label
                                className="control-label"
                                htmlFor="comment"
                            >
                                Commentaar
                            </Form.Label>
                            <Form.Control
                                onChange={this.onChange}
                                as={'textarea'}
                                id="jwwmo-comment"
                                name="comment"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.submit} variant="primary">
                        {this.state.saving ? (
                            <LoadingDefault size={'sm'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {kedo.t('Save')}
                    </Button>
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                    >
                        {kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default VerzoekToewijzingForm
